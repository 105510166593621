import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';

interface CompanySubscriptionSetContextValue {
  currentSubscriptionCount?: number;
  setCurrentSubscriptionCount: (count: number) => void;
  currentSubscriptionCountRefetch: () => void;
  setCurrentSubscriptionCountRefetch: (fn: () => void) => void;
}

const companySubscriptionSetContextDefaultValue = {
  setCurrentSubscriptionCount: noop,
  currentSubscriptionCountRefetch: noop,
  setCurrentSubscriptionCountRefetch: noop,
};

const CompanySubscriptionSetContext = createContext<CompanySubscriptionSetContextValue>(
  companySubscriptionSetContextDefaultValue,
);

export const CompanySubscriptionSetContextProvider = ({ children }: PropsWithChildren) => {
  const [currentSubscriptionCount, setCurrentSubscriptionCount] = useState<number>();
  const [currentSubscriptionCountRefetch, setCurrentSubscriptionCountRefetch] = useState<() => void>();

  const value = useMemo(
    () =>
      ({
        currentSubscriptionCount,
        setCurrentSubscriptionCount,
        currentSubscriptionCountRefetch,
        setCurrentSubscriptionCountRefetch: (fn) => setCurrentSubscriptionCountRefetch(() => fn),
      } as CompanySubscriptionSetContextValue),
    [currentSubscriptionCount, currentSubscriptionCountRefetch],
  );

  return <CompanySubscriptionSetContext.Provider value={value}>{children}</CompanySubscriptionSetContext.Provider>;
};

export const useCompanySubscriptionSetContext = () => useContext(CompanySubscriptionSetContext);
